import React, { useState, useEffect } from 'react';
import './news.css'
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const reqUrl = 'https://magenta-investments.com/Newsletters/wp-json/wp/v2/news?acf_format=standard&_filds=id,title,acf';

const News = () => {
  const [news, setNews] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); 
  const noNews = news.length === 0;

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const req = await fetch(reqUrl);
        const newsData = await req.json();
        setNews(newsData);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  const handleForward = () => {
    setStartIndex(prevIndex => (prevIndex + 1) % news.length);
  };

  const handleBackward = () => {
    setStartIndex(prevIndex => (prevIndex - 1 + news.length) % news.length);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Function to get the indices of the visible cards based on the startIndex
  const getVisibleIndices = () => {
    const visibleIndices = [];
    const cardCount = Math.min(news.length, windowWidth < 1200 ? 1 : 4);
    for (let i = 0; i < cardCount; i++) {
      visibleIndices.push((startIndex + i) % news.length);
    }
    return visibleIndices;
  };
  
  return (
    <section id='news'>
        <h2 className='news-container-title'>NEWS</h2>
        <div className='button-container'>
          <button className='btn-news btn-back' onClick={handleBackward}><IoIosArrowBack /></button>
          <button className='btn-news btn-forward' onClick={handleForward}><IoIosArrowForward /></button>
        </div>
        <div className={`news-card-container ${noNews? "no-news" : ""}`}>
          {noNews ? (
            <div className="no-news-message">No news available</div>
          ) : (
            getVisibleIndices().map(index => (
                <div className="news-card" key={news[index].id}>
                  <a href={news[index].acf.news_link} className='news-card-link'>
                    <div className='news-card-img'>
                      <img className='card-img' src={news[index].acf.news_image} alt={news[index].news_title} />
                    </div>
                    <div className="news-card-content">
                      <p className='news-card-content-title'>{news[index].acf.news_title}</p>
                      <p className='news-card-content-para'>{news[index].acf.news_content.substring(0,80)}...<a href={news[index].link}>Read more</a></p>
                    </div>
                  </a>
                </div>
            ))
          )}
        </div>
    </section>
  )
}

export default News;
