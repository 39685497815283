import './App.css';
import Main from './sections/main-section/Main';
import About from './sections/about-section/About';
import Companies from './sections/companies-section/Companies'
import Message from './sections/message-section/Message';
import News from './sections/news-section/News';
import Contact from './sections/contact-section/Contact'
import Footer from './sections/footer-section/Footer';
import Partners from './sections/partners-section/Partners';

function App() {
  return (
    <div className="App">
      <Main/>
      <About/>
      <Companies/>
      <Message />
      <News/>
      <Contact/>
      <Partners/>
      <Footer/>
    </div>
  );
}

export default App;
