import React from 'react'
import './introduction.css'
import { Link } from "react-scroll";

const Introduction = () => {
  return (
    <section id='introduction'>
        <div className='intro-content'>
            <h1 className='intro-heading intro-heading-1'>Innovative</h1>
            <h1 className='intro-heading intro-heading-2'>Healthcare</h1>
            <h1 className='intro-heading intro-heading-3'>Solutions</h1>
            <p className='intro-para'>
              MAGENTA is the healthcare arm of a leading UAE investment and industrial company with a diverse portfolio across multiple sectors.
            </p>
            <Link
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            to="about"
            className="navbar--content">
              <button className="btn btn-primary">ABOUT US</button>
            </Link>
        </div>
        <div className="card-container">
          <div className="column column-1">
            <div className="card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/intro-section-img/Business-Healthcare.jpg)`}}>
              <div className="overlay">
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <image
                    x="0"
                    y="0"
                    width="100%" 
                    height="100%" 
                    preserveAspectRatio="xMidYMin slice" 
                    xlinkHref={`${process.env.PUBLIC_URL}/intro-section-svg/Business-Healthcare.svg`}
                  />
                </svg>
                <h6 className="title">BUSINESS<br />HEALTHCARE</h6>
              </div>
            </div>
            <div className="card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/intro-section-img/tech.jpg)`}}>
              <div className="overlay">
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <image
                    x="0"
                    y="0"
                    width="100%" 
                    height="100%" 
                    preserveAspectRatio="xMidYMin slice" 
                    xlinkHref={`${process.env.PUBLIC_URL}/intro-section-svg/Technology-Healthcare.svg`}
                  />
                </svg>
                <h6 className="title">TECHNOLOGY<br />HEALTHCARE</h6>
              </div>
            </div>
          </div>
          <div className="column column-2">
          <div className="card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/intro-section-img/Medical-Research.jpg)`}}>
              <div className="overlay">
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <image
                    x="0"
                    y="0"
                    width="100%" 
                    height="100%" 
                    preserveAspectRatio="xMidYMin slice" 
                    xlinkHref={`${process.env.PUBLIC_URL}/intro-section-svg/Medical-Research.svg`}
                  />
                </svg>
                <h6 className="title">MEDICAL<br />RESEARCH AND<br />DEVELOPMENT</h6>
              </div>
            </div>
            <div className="card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/intro-section-img/medical-professionals.jpg)`}}>
              <div className="overlay">
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <image
                    x="0"
                    y="0"
                    width="100%" 
                    height="100%" 
                    preserveAspectRatio="xMidYMin slice" 
                    xlinkHref={`${process.env.PUBLIC_URL}/intro-section-svg/Medical-Professionals.svg`}
                  />
                </svg>
                <h6 className="title">MEDICAL<br />PROFESSIONALS</h6>
              </div>
            </div>
          </div>
          <div className="column column-3">
          <div className="card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/intro-section-img/health-and-wellness.jpg)`}}>
              <div className="overlay">
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <image
                    x="0"
                    y="0"
                    width="100%" 
                    height="100%" 
                    preserveAspectRatio="xMidYMin slice" 
                    xlinkHref={`${process.env.PUBLIC_URL}/intro-section-svg/Health-and-wellness.svg`}
                  />
                </svg>
                <h6 className="title">HEALTH AND<br />WELLNESS</h6>
              </div>
            </div>
            <div className="card" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/intro-section-img/patient-care.jpg)`}}>
              <div className="overlay">
                <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <image
                    x="0"
                    y="0"
                    width="100%" 
                    height="100%" 
                    preserveAspectRatio="xMidYMin slice" 
                    xlinkHref={`${process.env.PUBLIC_URL}/intro-section-svg/Patient-Care.svg`}
                  />
                </svg>
                <h5 className="title">PATIENT<br />CARE</h5>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
}

export default Introduction