import React from 'react'
import './partners.css'

const Partners = () => {
  return (
    <section id='partners'>
        <h1 className='partners-title'>OUR PARTNERS</h1>
        <div className="partners-icons">
          <div className='partnesrs-icon-container icon-container-1'>
            <img src="./partners-logos/ehs-logo.svg" alt="" className="partners-icon partners-icon-1" />
          </div>
          <div className='partnesrs-icon-container icon-container-2'>
            <img src="./partners-logos/dubai-health.svg" alt="" className="partners-icon partners-icon-2" />
          </div>
          <div className='partnesrs-icon-container icon-container-3'>
            <img src="./partners-logos/viatirs-logo.svg" alt="" className="partners-icon partners-icon-3" />
          </div>
          <div className='partnesrs-icon-container icon-container-4'>
            <img src="./partners-logos/council-arab-emirats.png" alt="" className="partners-icon partners-icon-4" />
          </div>
          <div className='partnesrs-icon-container icon-container-5'>
            <img src="./partners-logos/minisitry-of-health.svg" alt="" className="partners-icon partners-icon-5" />
          </div>
          <div className='partnesrs-icon-container icon-container-6'>
            <img src="./partners-logos/united-nation.svg" alt="" className="partners-icon partners-icon-6" />
          </div>
          <div className='partnesrs-icon-container icon-container-7'>
            <img src="./partners-logos/world-health.svg" alt="" className="partners-icon partners-icon-7" />
          </div>
          <div className='partnesrs-icon-container icon-container-8'>
            <img src="./partners-logos/community-of-deveopment.svg" alt="" className="partners-icon partners-icon-8" />
          </div>
          <div className='partnesrs-icon-container icon-container-9'>
            <img src="./partners-logos/careai.png" alt="" className="partners-icon partners-icon-9" />
          </div>
          <div className='partnesrs-icon-container icon-container-10'>
            <img src="./partners-logos/signature-care.svg" alt="" className="partners-icon partners-icon-10" />
          </div>
          <div className='partnesrs-icon-container icon-container-11'>
            <img src="./partners-logos/fids.svg" alt="" className="partners-icon partners-icon-11" />
          </div>
          <div className='partnesrs-icon-container icon-container-12'>
            <img src="./partners-logos/technical-servies.png" alt="" className="partners-icon partners-icon-12" />
          </div>
          <div className='partnesrs-icon-container icon-container-13'>
            <img src="./partners-logos/abudabi-capital.svg" alt="" className="partners-icon partners-icon-13" />
          </div>
          <div className='partnesrs-icon-container icon-container-14'>
            <img src="./partners-logos/heka.svg" alt="" className="partners-icon partners-icon-14" />
          </div>
          <div className='partnesrs-icon-container icon-container-15'>
            <img src="./partners-logos/mediserve.svg" alt="" className="partners-icon partners-icon-15" />
          </div>
        </div>
    </section>
  )
}

export default Partners