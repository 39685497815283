import React from 'react'
import './message.css'

const Message = () => {
  return (
    <section id='message'>
        <div className='message-container'>
            <div className='image-content'>
                <img className='ceo-image' src="/img/Dr-zahid-photo.png" alt="" />
                <h1>DR. ZAHID AL SABTI</h1>
                <p>CHIEF EXECUTIVE OFFICER</p>
            </div>
            <div className='message-heading'>
                <h6>A MESSAGE</h6>
                <h6>FROM</h6>
                <h6>OUR CEO</h6>
            </div>
            <div className='message-content'>
                <p><span className='para-starting'>MAGENTA</span> healthcare operations with the driving of providing transformative health services and hospital efficacy. Magenta Health delivers high performance systems and value-based care models into high-quality, cutting-edge facilities. 
                </p>
            </div>
        </div>
    </section>
  )
}

export default Message