import React from 'react'
import './companies.css'

const Companies = () => {
  return (
    <section id='companies'>
      <h2>OUR COMPANIES</h2>
      <div className='companies-card-container'>
        <a href='https://magenta-med.com/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-1'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/magenta-medical.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>Magenta Medical Investments is involved in the efficient management and operation of hospitals. This includes streamlining processes, optimising patient care, and ensuring that the highest standards of healthcare delivery are maintained.
              </p>
            </div>
          </div>
        </a>
        <a href='https://magenta-pharma.com/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-2'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/magenta-pharama.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>An integrated healthcare Company. Magenta Pharma provides medical, pharmaceutical, and nutrition products and services in the UAE. Launched with the focus of sourcing and distibuting quality medicine, consumables and equipment.
              </p>
            </div>
          </div>
        </a>
        <a href='https://magenta-rx.com/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-3'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/magenta-rx.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>Magenta Rx Compounding pharmacy is a state-of-art facility that is based in Dubai. Magenta Rx offers a wide variety of products that cover both sterile and non sterile divisions of compounding. Medicines are compounded within The Rx Pharmacy.
              </p>
            </div>
          </div>
        </a>
        <a href='https://magenta-homehealth.com/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-4'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/home-health.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>At Magenta Home Health we recognise that timely access to medical care is essential. Family and quality care is at the heart of Magenta Home Health and has always been very important to us. This is the number one reason Home Health decided to form a team of highly qualified health care professionals, who share the same passion of providing diligent care.
              </p>
            </div>
          </div>
        </a>
        <a href='https://healthplus-clinic.com/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-5'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/health-plus.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>Magenta Health Plus Clinics, an initiative by Magenta Investments in the NE region, is dedicated to delivering essential healthcare services to low-income individuals, affirming the company's commitment to community well-being.
              </p>
            </div>
          </div>
        </a>
        <a href='https://goodhealth-clinic.com/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-6'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/good-health.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>Good Health Clinics & Pharmacy, a part of Magenta Investments, is a leading healthcare provider committed to enhancing accessibility to high-quality medical services in the Sharjah region.
              </p>
            </div>
          </div>
        </a>
        <a href='https://bioservices.ae/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-7'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/ibs.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>UAE Bio Banking Enterprise is a cutting-edge biobanking organisation dedicated to the collection, storage, and distribution of biological samples for research, as well as the development of commercial services in the field of healthcare.
              </p>
            </div>
          </div>
        </a>
        <a href='https://nova-tech.ae/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-8'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/novatech.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>Nova Tech, established by Magenta Investments, is a leading provider of innovative IT solutions tailored for the healthcare sector. Nova Tech specialises in enhancing IT infrastructure and data management.
              </p>
            </div>
          </div>
        </a>
        <a href='https://nova-tech.ae/' rel="noreferrer" target='_blank'>
          <div className='companies-card card-9'>
            <div className='comanies-card-icon'>
              <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <image
                  x="0"
                  y="0"
                  width="100%" 
                  height="100%" 
                  preserveAspectRatio="xMidYMin slice" 
                  xlinkHref={`${process.env.PUBLIC_URL}/company-logos/ondemand.svg`}
                />
                </svg>
            </div>
            <div className='companies-card-content'>
              <p>Magenta On Demand is a leading provider of manpower solutions, offering a diverse spectrum of staffing services to meet the manpower needs of various sectors and industries. Magenta On Demand takes pride in our ability to supply both temporary and permanent manpower.
              </p>
            </div>
          </div>
        </a>
      </div>
    </section>
  )
}

export default Companies