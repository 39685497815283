import React from 'react'
import './about.css'

const About = () => {
  return (
    <section id='about'>
        <h2 className='about-top-heading'>We are Magenta</h2>
        <div className='about-card-container'>
            <div className='about-card about-card-1'>
                <svg className="about-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <image
                        x="0"
                        y="0"
                        width="100%" 
                        height="100%" 
                        preserveAspectRatio="xMidYMin slice" 
                        xlinkHref={`${process.env.PUBLIC_URL}/about-section-svg/mission.svg`}
                    />
                </svg>
                <h3>MISSION</h3>
                <p>Our mission is to be trusted by patients, a valued partner in the community, and creators of positive change.</p>
            </div>
            <div className='about-card about-card-2'>
                <svg className="about-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <image
                        x="0"
                        y="0"
                        width="100%" 
                        height="100%" 
                        preserveAspectRatio="xMidYMin slice" 
                        xlinkHref={`${process.env.PUBLIC_URL}/about-section-svg/vision.svg`}
                    />
                </svg>
                <h3>VISION</h3>
                <p>Our vision is to provide patient-centered healthcare products and services, with excellence in quality and access.</p>
            </div>
            <div className='about-card about-card-3'>
                <svg className="about-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <image
                        x="0"
                        y="0"
                        width="100%" 
                        height="100%" 
                        preserveAspectRatio="xMidYMin slice" 
                        xlinkHref={`${process.env.PUBLIC_URL}/about-section-svg/values.svg`}
                    />
                </svg>
                <h3>VALUES</h3>
                <p>Magenta is dedicated to cultivating strategic partnerships within the pharmaceutical and healthcare service sectors and we are committed to pioneering unique therapies and technologies.</p>
            </div>
        </div>
        <div className='about-content-container'>
            <div className='about-content'>
                <h1>ABOUT</h1>
                <p>Magenta is a prominent healthcare investment, service, trading, and industrial company, boasting a diverse portfolio spanning multiple sectors.</p>
                <p>The company is dedicated to cultivating strategic partnerships within the pharmaceutical and healthcare service sectors and is committed to pioneering unique therapies and technologies.</p>
                <p>With a dynamic leadership team and an innovative business model, Magenta is positioned as a major regional player in advancing health and revolutionizing care delivery, through cutting-edge technology across the Middle East and Africa.</p>
            </div>
            <div className='about-video'>
            <video width="100%" height="100%" autoPlay muted loop>
                <source src={`${process.env.PUBLIC_URL}/video/FRN_videoClip.mp4`} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            </div>
        </div>
    </section>
  )
}

export default About