import React, {useState} from 'react'
import './navbar.css'
import { FaSquareFacebook } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-scroll";

const Navbar = () => {

    const[active, setActive] = useState(false);
    const navClick = ()=>{
        setActive(!active);
    }
    const closeMenu = () => {
        setActive(false);
      };
  return (
    <nav id='navbar'>
        <div className='nav-logo'>
            <img className="navlogo" src="./Magentalogo.svg" alt="MAGENTA LOGO" />
        </div>
        <div className={`hamburger-menu ${active? "nav-active" : ""}`} onClick={navClick}>
            <span className={`nav-line ${active? "nav-active" : ""}`}></span>
            <span className={`nav-line ${active? "nav-active" : ""}`}></span>
            <span className={`nav-line ${active? "nav-active" : ""}`}></span>
        </div>
        <div className={`nav-links ${active? "nav-active" : ""}`}>
            <ul className='nav-link-container'>
                <li  className='nav-link-items'>
                    <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="mainSection"
                      // className="navbar--content"
                    >
                    HOME
                    </Link>
                </li>
                <li className='nav-link-items'>
                  <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="about"
                      // className="navbar--content"
                    >
                    ABOUT
                  </Link>
                </li>
                <li className='nav-link-items'>
                    <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="companies"
                      // className="navbar--content"
                    >
                    COMPANIES
                    </Link>
                </li>
                <li className='nav-link-items'>
                    <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="news"
                      // className="navbar--content"
                    >
                    NEWS
                    </Link>
                </li>
                <li className='nav-link-items'>
                  <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="contact"
                      // className="navbar--content"
                      >
                      CONTACT US
                  </Link>
                </li>
                <li className='nav-link-items'>
                  <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="partners"
                      // className="navbar--content"
                    >
                    PARTNERS
                  </Link>
                </li>
                <li className='nav-link-items'>
                  <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="career"
                      // className="navbar--content"
                    >
                    CAREER
                  </Link>
                </li>
            </ul>
        </div>
        <div className={`social-links ${active? "nav-active" : ""}`}>
            <ul className='social-link-container'>
              <li>
                <a
                  href="https://www.facebook.com"
                  className="social-link-items"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaSquareFacebook size={25} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/magenta-investments-llc/"
                  className="social-link-items"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin size={25} />
                </a>
              </li>
            </ul>
        </div>
    </nav>
  )
}

export default Navbar