import React from 'react'
import './main.css'
import Navbar from './main-section-components/Navbar'
import Introduction from './main-section-components/Introduction'

const Main = () => {
  return (
    <section id="mainSection" className="main-section">
      <Navbar/>
      <Introduction/>
    </section>
  )
}

export default Main